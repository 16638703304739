import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function WomenHoodiesPage() {
  return (
    <CategoryWrapper
      seoTitle="Trøjer"
      gender="dame"
      category="hoodies"
    />
  )
}

export default WomenHoodiesPage
